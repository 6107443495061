html {
  overflow-x: hidden;
}

body {
  overflow: hidden;
}

section.login-sec {
  width: 100%;
  float: left;
  height: 100vh;
  display: flex;
}

.main-form button a {
  color: #fff;
  text-decoration: none;
}


.login-one {
  width: 100%;
  height: 100vh;
  background: url(./images/Untitled.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 50px 50px 50px 50px;
}


.main-login .icon-box {
  display: flex;
  align-items: center;
  column-gap: 40px;
}

.main-login .icon {
  width: 8%;
}

.icon-text p.icon-tx {
  color: #0062F4;
  font-size: 19px;
  font-weight: bolder;
  font-family: 'Tw Cen MT Condensed', sans-serif;
  text-transform: uppercase;
  margin: 0;
  width: 100% !important;
}


.login-form h2.Hello {
  font-family: lato;
  font-size: 32px;
  font-weight: 900;
  margin: 0px !important;
  color: #000;
}

.login-form p.hello-tag {
  font-size: 16px;
  font-family: lato;
  font-weight: 400;
  margin: 0 0 10% 0;
  padding: 10px 0 0 0;
}

@media(max-width:768px) {
  .login-form p.hello-tag {
    margin: 0 0 20% 0;
  }
}


.main-form select {
  width: 100% !important;
}


.main-form input {
  width: 100% !important;
  padding: 12px;
  height: 30% !important;
}

.main-login .login-form {
  width: 100%;

}


.main-form p.Forgot-Password {
  FONT-FAMILY: 'LATO';
  font-size: 16px;
  color: red;
  text-align: end;
  margin: auto;

}




.main-form button {
  background: #0062F4;
  color: #fff;
  border: 0;
  font-size: 20px;
  font-weight: 400;
  font-family: 'Lato';
  border-radius: 5px;
  width: 100% !important;
  cursor: pointer;
  padding: 10px 20px;
  margin: 10% 0;
}

@media (max-width:768px) {
  .Loginform-container {
    padding-top: 10%;
  }
}

.main-login .account-Create {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.account-Create p {
  font-size: 16px;
  font-family: 'Lato';
  font-weight: 400;
  color: #848484;
  margin: 0;
  padding: 0 0 0;
}

.account-Create button {
  color: #0062F4;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Lato';
  background: transparent;
  border: none;
  cursor: pointer;
}


.login-two {
  width: 100%;
  height: 100vh;
  background: url(./images/Image.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  position: relative;
  align-items: flex-end;
}

.login-two .crousel {
  width: 100%;
  margin: 0 0 114px 0;
  padding: 0 125px 0 125px;
  box-sizing: border-box;
}

.login-two .crousel ul.slick-dots {
  bottom: -15px;
}

.crousel h3 {
  font-size: 28px;
  font-family: 'Lato';
  font-weight: 600;
  color: #ffffff;
  margin: auto;
  text-align: center;
}

.crousel p.crousel-tx {
  font-family: 'Lato';
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  line-height: 38px;
}

.crousel .crousel-Dot {
  display: flex;
  justify-content: center;
  align-items: center;
}



.header-nav ul {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  column-gap: 30px;
}

.header-nav ul li {
  list-style: none;
  display: inline-block;
}

.header-nav ul a img {
  filter: invert(38%) sepia(79%) saturate(7108%) hue-rotate(209deg) brightness(99%) contrast(103%);
  width: 24px;
}

.header-nav {
  display: flex;
  align-items: center;
}

header {
  width: 100%;
  float: left;
  background: #fff;
}

.main-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: auto;
  padding: 15px 15px 15px 15px !important;
  box-sizing: border-box;
  align-items: center;
  border-bottom: 1px solid #D9E1E7;
  overflow: hidden;
}

.main-header .back-button {
  width: 16%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media (max-width: 768px) {
  .back-button-tx {
    display: none;
  }

  .back-button .back-button-icon {
    width: 80% !important;
  }

  .main-header {
    padding: 15px 14% 15px 15px !important;

  }
}

@media (min-width:600px) and (max-width:768px) {
  .back-button .back-button-icon {
    width: 40% !important;
  }
}

@media (max-width:400px) {
  .back-button .back-button-icon {
    width: 60% !important;
  }

  .main-header {
    padding: 15px 18% 15px 15px !important;
  }

  .AssessorHeaderHeading {
    font-size: 16px !important;
    margin-top: 7% !important;
  }
}

.back-button .back-button-icon {
  width: 20%;
}

.back-button-icon img {
  width: 100%;
}

.back-button p.back-button-tx {
  font-weight: 600;
  font-family: lato;
  color: #1D1D1E;
  font-size: 17px;
  margin: 0px 10px;
}

.main-header .header-side-two {
  width: 40%;
  display: flex;
  align-items: center;
}



.header-side-two .notification-bell {
  width: 42px;
  height: auto;
}

.notification-bell img {
  width: 100%;
}



@media (max-width: 768px) {

  .notification-bell img {
    width: 120%;
  }

}

@media(max-width: 616px) {
  .notification-bell img {
    width: 200%;
    margin: 10%;
  }
}

@media(max-width:768px) {
  .user-info .user-img {
    display: none !important;
  }
}

@media (min-width: 769px) and (max-width: 991.98px) {
  .header-side-two .notification-bell {
    padding: 5%;
  }

  .notification-bell img {
    width: 200%;
  }
}

.header-side-two .user-info {
  width: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: auto;
  padding: 0 0 0 15px;
}

.user-info .user-img {
  width: 42px;
  padding: 0 5px 0 0;
}

section.sidebar ul a {
  text-decoration: none;
}

.user-img img {
  width: 100%;
}

.user-name-id h3.user-name {
  font-family: 'Lato';
  font-weight: 600;
  font-size: 16px;
  color: #1D1D1D;
  padding: 0 0 8px 0;
  margin: auto;
}


.user-name-id p.user-id {
  font-size: 12px;
  font-family: 'Lato';
  font-weight: 400;
  color: #828282;
  margin: auto;
}

@media(min-width:414px) {
  .user-name-id h3.user-name {
    white-space: nowrap;
  }
}


.main-footer {
  width: 100%;
  display: flex;
  align-items: top;
  justify-content: space-between;
}

@media(max-width:768px) {
  .footer {
    padding: 2px !important;
    text-align: center;
  }

  .main-footer {
    flex-direction: column;
    margin-left: 0px !important;
  }

  .f-nav ul {
    display: flex;
    flex-direction: column;
    column-gap: 0px;
    margin: 0%;
    padding: 0%;
  }

  .f-nav ul li {
    padding: 0px;
    margin: 2px !important;
  }

  .f-social ul {
    display: flex;
    justify-content: center !important;
    left: 0px;
  }

}

.footer {
  border-top: 2px solid rgb(196, 191, 191);
  width: 100%;
  padding: 20px;
  background-color: white;
  margin-top: auto;
}


.footer .main-footer .f-nav li {
  list-style: none;
  margin: 10px;
}

.footerText {
  margin-top: 6%;
}

.f-nav ul {
  display: flex;
  column-gap: 10px;
}

.f-nav ul a {
  text-decoration: none;
  color: #000;
}

.f-social ul {
  display: flex;
  padding: 0%;
  column-gap: 10px;

}

.f-social ul li {
  list-style: none;
}



.main-footer .footer-col-1 {
  width: 25%;
  padding: 0 25px 0 30px;
  box-sizing: border-box;
}

.footer-col-1 .icon-box {
  width: 100%;
  height: 10vh !important;
  display: flex;
  margin-bottom: 100px !important;
}

.footer-col-1 .icon {
  width: 14%;
}

.footer-col-1 .icon-text {
  width: 84%;
}

.footer-col-1 p.footer-tx {
  font-size: 14px;
  font-family: 'Lato';
  font-weight: 400;
  line-height: 24px;
  color: #333333;
}

.main-footer .footer-col-2 {
  width: 13%;
  margin: 0 0 0 100px;
}

.footer-col-2 h4.Resources {
  font-family: 'Lato';
  font-size: 17px;
  font-weight: 600;
  margin: auto;
}

.footer-col-2 ul.footer-ul {
  list-style: none;
  padding: 7px 0 0px 0;
}

.Resources-li a {
  text-decoration: none;
  color: #333333;
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 400;

  line-height: 34px;
}

.footer-col-2 .social-icons {
  display: flex;
  width: 65%;
  justify-content: space-between;
}



section.banner {
  width: 100%;
  float: left;
  background: url(./images/Banner.jpg);
  height: 320px;
  padding-left: 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-sizing: border-box;
}

.banner .main-banner {
  width: 100% !important;
  padding: 12% 0 0 0;
  margin: auto;
}

.main-banner .unit-1 {
  display: flex;
  align-items: center;
  width: 30%;
}

.unit-1 .unit-icon {
  width: 10%;
}

.unit-icon img {
  width: 100%;
}

.unit-1 p.unit-1-tx {
  font-size: 25px;
  font-family: 'Lato';
  font-weight: 700;
  color: #ffffff;
  margin: 0 0 0 8px;
}

@media(min-width:1500px) {
  .main-banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 4% 0 0 0 !important;
  }
}

.main-banner h2.unit-1-heading {
  font-size: 42px;
  color: #ffffff;
  font-family: 'Lato';
  margin: auto;
}

@media(max-width:768px) {
  .unit-1 p.unit-1-tx {
    font-size: 20px;
  }
}




section.sec-2-unit-Overview {
  width: 100%;
  float: left;
  padding: 40px;
  box-sizing: border-box;
  display: flex;
}

.sec-2-unit-Overview .main-sec-2-unit-Overview {
  width: 1376px;
  display: flex;
  justify-content: space-between;
  margin: auto;
}

.main-sec-2-unit-Overview .QCF-Level-box {
  padding: 12px;
  display: flex;
  flex-direction: row;
  width: 30%;
  border: 1px solid rgb(202, 201, 201);
  border-radius: 15px;
  justify-content: space-around;
  align-items: center;
  white-space: nowrap;
}

.QCF-Level-box .QCF-Level-icon-box {
  display: flex;
  width: 50%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}



.QCF-Level-icon-box .QCF-Level-icon {
  width: 26%;
}

.QCF-Level-icon img {
  width: 100%;
}

.QCF-Level-icon-box p.QCF-Level-tx {
  font-size: 24px;
  font-family: 'Lato';
  font-weight: bold;
  margin: 0 0px 0 6px;
}

.QCF-Level p.QCF-Level-number {
  font-size: 24px;
  font-family: 'Lato';
  font-weight: bold;
  margin: auto;
}



@media(max-width:768px) {
  .main-sec-2-unit-Overview .QCF-Level-box {
    font-size: 10px !important;
    padding: 1% 0% !important;
    display: flex;
    flex-direction: column;
  }

  .QCF-Level-icon-box {
    display: flex;
    flex-direction: column !important;
  }

  .QCF-Level-icon-box p.QCF-Level-tx {
    font-size: 12px;
    margin: 0 !important
  }

  .QCF-Level p.QCF-Level-number {
    font-size: 14px;
  }

}

@media(min-width:768px) and (max-width:1000px) {
  .QCF-Level-icon-box p.QCF-Level-tx {
    font-size: 13px;
    margin: 0 !important;
    white-space: nowrap;
    margin-left: 10px !important;
  }

  .QCF-Level-number {
    font-size: 14px !important;
  }

  .main-sec-2-unit-Overview .QCF-Level-box {
    padding: 12px 2px;
    display: flex;
    flex-direction: column;
    width: 30%;
    border: 1px solid rgb(202, 201, 201);
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
  }
}

@media(min-width:912px) and (max-width:1200) {
  .main-sec-2-unit-Overview .QCF-Level-box {
    justify-content: space-between;

  }
}

@media(max-width:280px) {
  .main-sec-2-unit-Overview .QCF-Level-box {
    padding: 4% 1% 4% 4% !important;
  }

  .QCF-Level-icon-box p.QCF-Level-tx {
    font-size: 12px;
    margin: 0 !important
  }

  .QCF-Level p.QCF-Level-number {
    font-size: 12px;
  }
}

section.sec-3-unit-Overview {
  width: 100%;
  float: left;
  box-sizing: border-box;
  padding: 10px 40px 40px 40px;
  display: flex;
}

.sec-3-unit-Overview .main-sec-3-unit-Overview {
  width: 1376px;
  float: left;
  margin: auto;
  display: table;
}

.main-sec-3-unit-Overview h2.sec-3-heading {
  font-size: 35px;
  font-family: 'Lato';
  font-weight: bold;
  margin: auto;
}

.main-sec-3-unit-Overview .sec-3-unit-Overview-tx-img {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-sec-3-unit-Overview p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0 !important;
  padding: 0 !important;
}

.sec-3-unit-Overview-tx-img .sec-3-unit-Overview-tx {
  width: 60%;
}

.sec-3-unit-Overview-tx-img .sec-3-unit-Overview-img {
  width: 40%;
}

.sec-3-unit-Overview-img img {
  width: 100%;
}

.sec-3-unit-Overview-tx p.sec-3-unit-Overview-text {
  font-size: 20px;
  font-weight: 400;
  color: #000;
  font-family: 'Lato';
  line-height: 32px;
  margin: auto;
}


.sec-3-unit-Overview p.sec-3-unit-Overview-text-2 {
  font-size: 20px;
  font-weight: 400;
  color: #000;
  font-family: 'Lato';
  line-height: 32px;
  margin: auto;
}

.container {
  width: 1376px;
  margin: auto;
}

section.sec-4-unit-Overview {
  width: 100%;
  float: left;
  padding: 2%;
}

.sec-4-unit-Overview-img-boxes {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.sec-4-unit-Overview-img-boxes .img-boxes-1 {
  width: 300px;
  height: 200px;
  padding: 4% 1%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #0062F4;
  border-radius: 15px;
  background: #f4f8ff;
  text-decoration: none;
  color: #000;
  margin: 0.8%;
  background-color: rgb(238, 249, 253);
}

.Frame-img img {
  width: 60%;
}

.img-boxes-1 .Learning-Materials-img-box {
  width: 100%;
  text-align: center;
}

.Learning-Materials-img-box .Frame-img {
  width: 50%;
  margin: auto;
}

.Learning-Materials-img-box p.Learning-Materials-tx {
  font-size: 24px;
  font-family: 'Lato';
  font-weight: bold;
  margin: auto;
  padding: 15px 0 0 0;
}

@media(max-width:780px) {
  .Learning-Materials-img-box p.Learning-Materials-tx {
    font-size: 18px;
  }
}

section.sec-5-unit-Overview {
  width: 100%;
  float: left;
  display: flex;
  padding: 2%;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.sec-5-unit-Overview-container .main-sec-5-unit-Overview {
  width: 100%;

}

.main-sec-5-unit-Overview h3.sec-5-unit-Overview-heading {
  font-size: 32px;
  font-family: 'Lato';
  font-weight: 800;
  margin: 0 0 44px 0;
  color: #fff;
}

.sec-5-unit-Overview-container {
  width: 100%;
  float: left;
  margin: auto;
  display: table;
  background: url(./images/banner-2.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 30px;
  box-sizing: border-box;
  border-radius: 15px;
}

.main-sec-5-unit-Overview .Study-Guide {
  display: flex;
  align-items: center;
  width: 40%;
  padding: 10px 0 2px 0;
}

.Study-Guide .Study-Guide-icon {
  width: 5%;
}

.Study-Guide-icon img {
  width: 100%;
}

@media(max-width:768px) {
  .main-sec-5-unit-Overview .Study-Guide {
    width: 100%;
  }

  .Study-Guide p.Study-Guide-tx {
    font-size: 13px !important;
  }

  .Study-Guide .Study-Guide-icon {
    width: 12%;
  }
}

.Study-Guide p.Study-Guide-tx {
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  margin: 0 0 0 15px;
}

section.sec-5-unit-Overview {
  padding: 50px 0 50px 0;
}

@media(min-width:1280px) and (max-width:1360px) {
  .sec-4-unit-Overview-img-boxes .img-boxes-1 {
    margin: 0%;
  }
}

@media(min-width:1367px) {
  section.sec-5-unit-Overview {
    padding: 30px 3% !important;
  }

}

@media(min-width:1500px) {
  section.sec-5-unit-Overview {
    padding: 30px 4% !important;
  }

  .banner .main-banner {
    margin: 0px 4% !important;
  }
}

@media(min-width:1800px) {
  section.sec-5-unit-Overview {
    padding: 30px 6% !important;
  }

  .banner .main-banner {
    margin: 0px 6% !important;
  }
}

@media(min-width:2000px) {
  section.sec-5-unit-Overview {
    padding: 30px 8% !important;

  }

  .banner .main-banner {
    margin: 0px 8% !important;
  }
}

@media(min-width:2200px) {
  section.sec-5-unit-Overview {
    padding: 30px 11% !important;

  }

  .banner .main-banner {
    margin: 0px 11% !important;
  }
}

section.banner-2 {
  width: 100%;
  float: left;
  background: url(./images/banner-two.jpg);
  height: 320px;
  padding: 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-sizing: border-box;
}

.banner-2 .main-banner {
  width: 100%;
  padding: 12% 0 0 0;
  margin: auto;
}

@media (max-width:768px) {
  .banner-2 .main-banner {
    width: 100%;
    padding: 12% 0 0 0;
    margin: auto;
  }
}

.removeScrollBar {
  overflow: hidden
}

section.sec-two-Underpinning-Questions {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0 50px 0;
}

h2.sec-2-heading {
  font-size: 35px;
  font-family: 'Lato';
  font-weight: bold;
  margin: auto;
}

@media(max-width:768px) {
  h2.sec-2-heading {
    font-size: 30px;
  }
}

p.sec-2-Underpinning-Questions-tx {
  font-family: 'Lato';
  font-size: 20px;
  font-weight: 400;
  color: #000;
  margin: auto;
  padding: 20px 0 20px 0;
}

.sec-two-Underpinning-Questions .Question-1 {
  border: 1px solid blue;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 0px 0 10px 0;
  margin: 20px 0 0 0;
}

.Question-1 .Question-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 5px 15px 15px;
  box-sizing: border-box;
  background: #0062f414;
  border-radius: 12px 12px 0 0;
}

.pass {
  background: rgb(172, 255, 172) !important;
  color: green !important;
}


.fail {
  background: rgb(255, 219, 219) !important;
  color: red !important;
}

.grade-dropdown option.text-danger {
  background-image: url("../src/images/tick-square.png");
  background-repeat: no-repeat;
  background-position: center center;
  padding-left: 25px !important;
  width: 24px;
  height: 24px;
}

.grade-dropdown option.text-success {
  background-image: url("../src/images/tick-square.png");
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 25px;
}

.Question-bar h4.Question {
  font-size: 16px;
  font-family: 'Lato';
  font-weight: bold;
  color: #0062F4;
  width: 35%;
  margin: 0 0 0 0;
}

.Question-bar .save-icon-box {
  display: flex;
  justify-content: right;
  align-items: center;
  width: 20%;
  cursor: pointer;
}

.save-icon-box .save-icon {
  width: 12%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5%;
  cursor: pointer;
}

@media(max-width:768px) {
  .save-icon-box .save-icon {
    margin-left: 10%;
  }
}

.save-icon img {
  width: 66%;
}

.save-icon-box p.save-tx {
  font-family: 'Lato';
  font-size: 12px;
  font-weight: bold;
  margin: 0 0 0 0;
  color: #0062f478;
}

.Question-1 .type-bar {
  box-sizing: border-box;
  padding: 22px 10px 22px 15px;
}

.type-bar h3.type-bar-heading {
  font-family: 'Lato';
  font-size: 20px;
  font-weight: 500;
  margin: auto;
  padding: 5px 0 25px 0;
  line-height: 30px;
}

.type-bar input[type="text"] {
  width: 97%;
  padding: 19px;
  border-radius: 4px;
  border: 1px solid #0000005e;
  font-size: 20px;
  font-family: 'Lato';
  font-weight: 400;
  font-style: italic;
  color: #00000078;
}

.type-bar textarea {
  width: 97%;
  padding: 19px;
  border-radius: 4px;
  border: 1px solid #0000005e;
  font-size: 20px;
  font-family: 'Lato';
  font-weight: 400;
  font-style: normal;
  color: #000;
  box-sizing: border-box;
  height: 64px;
}

.type-bar textarea::placeholder {
  font-size: 20px;
  font-family: 'Lato';
  font-weight: 400;
  font-style: normal;
  color: #000;
}

.sec-two-Underpinning-Questions button.btns1 {
  padding: 15px 23px 15px 23px;
  font-family: 'Lato';
  font-size: 18px;
  font-weight: 500;
  background: #0159dc;
  color: #fff;
  border: none;
  border-radius: 5px;
  margin: 45px 0 0 0;
  cursor: pointer;
}

.grayed-out-button {
  background-color: #ccc !important;
  cursor: not-allowed !important;
}

.sec-two-Underpinning-Questions-container {
  width: 90%;
}

.type-bar h4.Answer {
  font-size: 18px;
  font-family: 'Lato';
  font-weight: bold;
  color: blue;
  margin: auto;
  padding: 0 0 12px 18px;
}

.type-bar h3.type-bar-heading-2 {
  font-family: 'Lato';
  font-size: 20px;
  font-weight: 500;
  margin: auto;
  padding: 5px 0 5px 18px;
  line-height: 30px;
}

.type-bar h3.type-bar-heading-3 {
  font-family: 'Lato';
  font-size: 20px;
  font-weight: 500;
  margin: auto;
  padding: 5px 10px 5px 18px;
  line-height: 30px;
  border: 1px solid rgba(3, 3, 3, 0.61);
  border-radius: 7px;
}

section.banner-3 {
  width: 100%;
  float: left;
  background: url(./images/banner-five.jpg);
  height: 340px;
  padding: 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-sizing: border-box;
}

.banner-3 .main-banner {
  width: 100%;
  padding: 12% 0 0 0;
  margin: auto;
}

.type-bar .Entry {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  position: relative;
  margin: 0 0 60px 0;
}

@media(max-width:768px) {
  .Job-Records-sec .type-bar .Entry {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;

  }

  .Job-Records-sec .type-bar .Entry .btns2 {
    padding: 10px 20px;
  }
}

.Entry h3.Job-Records {
  font-family: 'Lato';
  font-weight: bold;
  font-size: 24px;
  margin: 0 0 0 0;
}

.type-bar .Entry>div {
  width: 48%;
  position: relative;
}

.Entry select#Unit {
  width: 100%;
  padding: 15px;
  font-size: 20px;
  font-family: 'Lato';
  position: relative;
  font-weight: 500;
}

.Entry select#jobs {
  width: 100%;
  padding: 15px;
  font-size: 20px;
  font-family: 'Lato';
  font-weight: 500;
}

section.sec-two-Underpinning-Questions {
  position: relative;
}

.type-bar .Entry select {
  position: relative;
  border: 1px solid #C9C9C9;
  border-radius: 10px;
  appearance: none;
  outline: none;
  background: transparent;
}

.type-bar .Entry>div::before {
  content: '';
  background: url(./images/Group-15565.png);
  width: 58px;
  position: absolute;
  height: 52px;
  right: 1px;
  z-index: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 0 10px 10px 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.sec-two-Underpinning-Questions button.btns2 {
  padding: 15px 50px 15px 50px;
  font-family: 'Lato';
  font-size: 18px;
  font-weight: 500;
  background: #0062F4;
  color: #fff;
  border: none;
  border-radius: 5px;
  margin: 30px 0 0 10px;
  cursor: pointer;
}

.sec-two-Underpinning-Questions .Question-1.disabled-div {
  border: 1px solid gray;
}

.sec-two-Underpinning-Questions .Question-1.disabled-div .Question-bar {
  background: #8080801a;
}

.sec-two-Underpinning-Questions .Question-1.disabled-div h4.Question {
  color: gray;
}

.type-bar textarea.disabled-input {
  opacity: 0.5;
  pointer-events: none;
}

.type-bar hr {
  width: 98%;
  margin: 30px 0px 2px 10px;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000061;
  z-index: 999;
}

.modal-wrapper .modal-inner div {
  width: 500px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-shadow: 0 6px 11px #0000002b;
  padding: 40px;
  border-radius: 10px;
  text-align: center;
  flex-direction: column;
  border: 4px solid #0159dc;
}

.modal-wrapper .modal-inner h2 {
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 40px 0;
}

.modal-wrapper .modal-inner button {
  padding: 10px 30px;
  font-family: 'Lato';
  font-size: 18px;
  font-weight: 500;
  background: #0159dc;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-wrapper .modal-inner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.type-bar h3.type-bar-sub-heading {
  font-family: 'Lato';
  font-size: 20px;
  font-weight: 500;
  margin: auto;
  padding: 5px 0 25px 0;
  line-height: 30px;
}

section.sec-1-Student-Profile-Progress {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 50px 0 50px 0;
}

.main-sec-1-Student-Profile-Progress {
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 15px;
  border: 1px solid #0062F4;
  border-radius: 10px;
  background-color: #f4f8ff;
}

.main-sec-1-Student-Profile-Progress .col-1 {
  width: 12%;
  text-align: center;
  padding: 0 10px 0 0;
}

.col-1 h4.student-name {
  font-family: 'Lato';
  font-size: 16px;
  font-weight: bold;
  margin: 8px 0 0 0;
}

.main-sec-1-Student-Profile-Progress .col-2 {
  width: 35%;
  padding: 0 0 0 52px;
  box-sizing: border-box;
  border-right: 1px solid #0062F4;
  border-left: 1px solid #0062F4;
}

.col-2 h3.Course {
  font-family: 'Lato';
  font-weight: bold;
  font-size: 24px;
  color: #0062F4;
  margin: auto;
}

.col-2 p.Course-tag {
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 400;
  margin: 12px 0 0 0;
}

.main-sec-1-Student-Profile-Progress .col-3 {
  width: 45%;
  padding: 0 0 0 52px;
  box-sizing: border-box;
}

.col-3 h3.student-percentage {
  font-family: 'Lato';
  font-weight: bold;
  font-size: 24px;
  color: #0062F4;
  margin: auto;
}

.col-3 p.percentage-tag {
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 400;
  margin: 12px 0 0 0;
}

.progress-tracker-input {
  outline: none;
  border: 1px solid rgb(160, 160, 160);
  border-radius: 4px;
  width: 10%;
  background-color: inherit;
  height: 35px;
  text-align: center;
  font-size: 18px;

}

@media(max-width:320px) {
  .main-sec-1-Student-Profile-Progress .col-2 {
    padding: 0px 0px 0px 25px;
  }

  .main-sec-1-Student-Profile-Progress .col-3 {
    padding: 0px 0px 0px 25px;
  }
}

@media(min-width:371px) and (max-width:768px) {
  .main-sec-1-Student-Profile-Progress {
    width: 100% !important;
    display: flex;
  }

  .main-sec-1-Student-Profile-Progress .col-1 {
    width: 20%;
    text-align: center;
    padding: 0 !important;
  }

  .main-sec-1-Student-Profile-Progress .col-2 {
    width: 45%;
    box-sizing: border-box;
    border-right: none;
    border-left: none;
  }

  .main-sec-1-Student-Profile-Progress .col-3 {
    width: 45%;
    box-sizing: border-box;
  }
}

@media(max-width:370px) {
  .main-sec-1-Student-Profile-Progress {
    width: 100% !important;
    display: flex;
    font-size: 10px !important;
  }

  .main-sec-1-Student-Profile-Progress .col-1 {
    width: 20%;
    text-align: center;
    padding: 0 !important;
    font-size: 10px !important;
  }

  .main-sec-1-Student-Profile-Progress .col-2 {
    width: 45%;
    box-sizing: border-box;
    border-right: none;
    border-left: none;
    font-size: 10px !important;
  }

  .main-sec-1-Student-Profile-Progress .col-3 {
    width: 45%;
    box-sizing: border-box;
    font-size: 10px !important;
  }

  .col-2 p.Course-tag {
    font-size: 12px;
    font-weight: 400;
  }

  .col-3 p.percentage-tag {
    font-size: 12px;
  }

  .col-2 h3.Course {
    font-size: 20px;
  }

  .col-3 h3.student-percentage {
    font-size: 20px;
  }
}

.main-header h3.Student-Profile-page-heading {
  font-family: 'Lato';
  font-weight: bolder;
  font-size: 24px;
  margin: auto;
  color: #0062F4;
}

section.sec-2-Student-Profile-Progress {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 30px 0 50px 0;
}

.main-sec-2-Student-Profile-Progress {
  width: 100%;
  box-sizing: border-box;
  padding: 0 0 0 0;
  border: 1px solid #c9c9c9b0;
  border-radius: 8px;
}

.main-sec-2-Student-Profile-Progress .main-1-Student-Profile-Progress {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 18px;
}

.unit-progress h4.unit-progress-heading {
  font-family: 'Lato';
  font-size: 20px;
  font-weight: bold;
  color: #1D1D1D;
  margin: auto;
}

.unit-progress p.unit-progress-tx {
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 400;
  margin: 12px 0 0 0;
}

.main-1-Student-Profile-Progress select#progress-percentage {
  width: 8%;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 6px;
  font-size: 20px;
  font-family: 'Lato';
  font-weight: 500;
  color: #64A338;
  border: 1px solid #00000036;
  background-color: #64a3380d;
}

.main-sec-2-Student-Profile-Progress hr {
  border-color: #0000001a;
  border-bottom: 1px;
}

.FeedBackInputContainer {
  margin: 2%;
}

.FeedBackInput {
  height: 40px;
  width: 100%;
  padding: 1%;
}

.MyFeedBackHeading {
  color: red;
  margin: 0% 2%;
}

.MyFeedBack {
  margin: 2%;
  font-size: 16px !important;
  background-color: #e0edff;
  padding: 1%;
  border-radius: 2px;
}

@media(max-width:300px) {
  .main-1-Student-Profile-Progress select#progress-percentage {
    width: 50% !important;
    padding: 12px 2px !important;
    font-size: 12px;
  }
}

@media (min-width:301px) and (max-width:400px) {
  .main-1-Student-Profile-Progress select#progress-percentage {
    width: 40% !important;
    padding: 12px 2px !important;
    font-size: 14px;
  }
}

@media(min-width:401px) and (max-width:767px) {
  .main-1-Student-Profile-Progress select#progress-percentage {
    width: 20% !important;
    padding: 12px 2px !important;
    font-size: 14px;
  }
}

@media(min-width:768px) and (max-width:997px) {
  .main-1-Student-Profile-Progress select#progress-percentage {
    width: 15%;
    padding: 12px 10px !important;
    font-size: 16px;
  }
}

@media(min-width:997px) and (max-width:1200px) {
  .main-1-Student-Profile-Progress select#progress-percentage {
    width: 10%;
    padding: 12px 10px !important;
    font-size: 14px;
  }
}

.sec-2-Student-Profile-Progress button.btns1 {
  padding: 10px 30px 10px 30px;
  font-family: 'Lato';
  font-size: 18px;
  font-weight: 500;
  background: #0062F4;
  color: #fff;
  border: none;
  border-radius: 5px;
  margin: 45px 0 0 0;
}

@media(max-width:768px) {
  .sec-2-Student-Profile-Progress .container {
    width: 100%;
    padding: 0px !important;
  }

  .sec-2-Student-Profile-Progress .container {
    width: 100%;
    padding: 0px !important;
  }

  .JobEntryBar .Question-1 .Question-bar {
    padding: 0% !important;
  }
}

.sec-2-Student-Profile-Progress .Question-1 {
  border: 1px solid #0062F4;
  border-radius: 12px;
  box-sizing: border-box;
  margin: 20px 0 0 0;
  padding: 0 0 0 0;
}

.Question-bar .Student-Profile-Assignments-icons-boxes {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.Student-Profile-Assignments-icons-boxes .save-icon-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.Student-Profile-Assignments-icons-boxes .save-icon {
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Student-Profile-Assignments-icons-boxes p.save-tx {
  font-family: 'Lato';
  font-size: 13px;
  font-weight: bold;
  margin: 0px;
  color: #0062f4;
  width: fit-content;
  white-space: nowrap;
}

.custom-border {
  border-right: 1px solid blue;
  height: 60px !important;
}

@media(max-width:768px) {
  .custom-border {
    border-right: none !important;
  }

  .css-1z0f1xt-placeholder,
  .css-1kx0j74-placeholder {
    font-size: 12px !important;
  }

  .css-yteih-placeholder {
    font-weight: 200 !important;
    font-size: 12px !important;
  }

  .css-1aa4g5r-control {
    min-height: 0px !important;
    max-height: 10px !important;
    margin: 0px 0px !important;
    padding: 0px !important;
    width: 80px !important;
  }

  .css-3sp780-singleValue {
    font-weight: 200 !important;
    font-size: 12px !important;
  }

  .Question-1 .Question-bar {
    height: 60px !important;
    align-items: flex-start !important;
  }

  .selectimage {
    display: none;
  }

}

.type-bar h4.Unit-1 {
  font-size: 18px;
  font-family: 'Lato';
  font-weight: bold;
  color: black;
  margin: auto;
  padding: 12px 0 5px 18px;
}


.type-bar h3.abc {
  font-family: 'Lato';
  font-size: 17px;
  font-weight: 600;
  margin: auto;
  padding: 5px 0 25px 18px;
  line-height: 30px;
}


@media(max-width:768px) {
  .Student-Profile-Assignments-icons-boxes .save-icon-box {
    width: fit-content;
    width: 33%;
  }

  .Student-Profile-Assignments-icons-boxes p.save-tx {
    font-family: 'Lato';
    font-size: 12px;
    font-weight: bold;
    margin-right: 3px !important;
    color: #0062f4;
    white-space: pre-wrap;
    line-height: normal;
  }

  .pass {
    font-size: 12px !important;
  }

  .fail {
    font-size: 12px !important;
  }

  .Question-bar .Student-Profile-Assignments-icons-boxes {
    width: 60%;
    align-items: flex-start;
    justify-content: flex-end !important;
  }

  .accordion-box {
    align-items: flex-start !important;

  }

  .Student-Profile-Assignments-icons-boxes .save-icon {
    display: none;
  }
}

@media(max-width:375px) {
  .Student-Profile-Assignments-icons-boxes p.save-tx {
    font-size: 11px;
    align-items: flex-start !important;
  }
}

.calendar-heading-button button.btns2 {
  padding: 10px 30px 10px 30px;
  font-family: 'Lato';
  font-size: 18px;
  font-weight: 500;
  background: #0062F4;
  color: #fff;
  border: none;
  border-radius: 5px;
  margin: 0px 0 0 0;
}

.sec-2-Student-Profile-Progress .calendar-heading-button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.calendar-heading-button h2.sec-2-heading {
  font-size: 35px;
  font-family: 'Lato';
  font-weight: bold;
  margin: 0 0 0 0;
}

.sec-2-Student-Profile-Progress .calendar-boxes {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 3px 0 0 0;
  flex-wrap: wrap;
  align-items: stretch;

}

.calendar-boxes .box-1 {
  width: 22%;
  box-sizing: border-box;
  padding: 21px;
  border: 1px solid #0000000f;
  border-radius: 6px;
  min-height: 300px;
  margin: 10px;
}

.calendar-buttongroup {
  display: flex;
  column-gap: 10px;
  padding: 0px 10px;
}

.custom-date-cell.highlighted {
  background-color: blue;
}

.ant-picker-cell-inner.has-tasks {
  background-color: #e6f7ff;
}

.box-1 .box-top-bar {
  display: flex;
  justify-content: space-between;
}

.box-top-bar .Deadline-icon-box {
  width: 50%;
  display: flex;
  align-items: center;
}

.Deadline-icon-box .Deadline-icon {
  width: 33%;
}

.Deadline-icon-box h3.Deadline-tx {
  font-family: 'Lato';
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 0 7px;
  color: #ff5100;
}

@media (min-width:500px) and (max-width:1168px) {
  .Deadline-icon-box .Deadline-icon {
    width: 22% !important;
  }
}

.box-top-bar .side-icon {
  width: 11%;
}

.side-icon img {
  width: 100%;
}

.box-1 p.Deadline-date {
  font-size: 18px;
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
}

.box-1 h4.Title-goes-here {
  font-size: 20px;
  font-family: 'Lato';
  font-weight: 400;
}

.box-1 p.Personal-note {
  font-size: 16px;
  font-family: 'Lato';
  font-weight: bold;
  color: #0062f4;
}

.box-1 p.Lorem {
  font-family: 'Lato';
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 15px;
}

tr:nth-child(even) {
  background-color: #dddddd1c;

}

tr.First-row {
  background-color: #f4f8ff;
  box-sizing: border-box;
  border-top: 1px solid blue;
  border-bottom: 1px solid blue;
}

button.btns5 {
  font-family: 'Lato';
  font-size: 18px;
  font-weight: 500;
  background-color: #64A338;
  color: #ffffff;
  border: 0px;
  box-sizing: border-box;
  padding: 8px 35px 8px 35px;
  border-radius: 5px;
  white-space: nowrap;
}

button.btns5Failed {
  font-family: 'Lato';
  font-size: 18px;
  font-weight: 500;
  background-color: red;
  color: #ffffff;
  border: 0px;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 8px 35px 8px 35px;
}

button.btns5Draft {
  font-family: 'Lato';
  font-size: 18px;
  font-weight: 500;
  background-color: #fe5101;
  color: #ffffff;
  border: 0px;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 8px 35px 8px 35px;
}

button.btns5Pending {
  font-family: 'Lato';
  font-size: 18px;
  font-weight: 500;
  background-color: #fe5101;
  color: #ffffff;
  border: 0px;
  box-sizing: border-box;
  padding: 8px 35px 8px 35px;
  border-radius: 5px;
  white-space: nowrap;
}

@media(max-width:768px) {
  .calendar-boxes .box-1 {
    width: 100%;
  }
}

@media(min-width:769px) and (max-width:1176px) {
  .calendar-boxes .box-1 {
    width: 50%;
  }
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.jobAssessmentrecordTable td {
  border-top: 1px solid rgba(0, 0, 0, 0.144);
  border-bottom: 1px solid rgba(0, 0, 0, 0.144);
  text-align: left;
  padding: 25px;
  width: 20%;
}

.job_enteries_title {
  color: #0062F4;
}

@media(max-width: 768px) {

  .jobAssessmentrecordTable th,
  .jobAssessmentrecordTable td {
    padding: 10px !important;
    margin: 2px !important;
  }
}

th {
  text-align: left;
  padding: 25px;
}

tr:nth-child(odd) {
  background-color: #dddddd2c;
}

tr.First-row {
  background-color: #f4f8ff;
  box-sizing: border-box;
  border-top: 1px solid blue !important;
  border-bottom: 1px solid blue !important;
}

button.btns6 {
  font-family: 'Lato';
  font-size: 18px;
  font-weight: 500;
  background-color: #FF5100;
  color: #ffffff;
  border: 0px;
  box-sizing: border-box;
  padding: 8px 18px 8px 18px;
  border-radius: 5px;
}

.Review-icon-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 49%;
  box-sizing: border-box;
  padding: 10px 20px 10px 20px;
  border: 1px solid blue;
  border-radius: 5px;
  background-color: #0062f40d;
}

@media(max-width:922px) {
  .Review-icon-box {
    width: 100%;
  }
}

.Review-icon-box .Review-icon {
  width: 23%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Review-icon img {
  width: 100%;
}

.Review-icon-box h4.Review-tx {
  color: #0062F4;
  font-size: 18px;
  font-family: 'Lato';
  font-weight: 400;
  margin: auto;
}

.edit-red-icon-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 49%;
  box-sizing: border-box;
  padding: 10px 20px 10px 20px;
  border: 1px solid #FF5100;
  border-radius: 5px;
  background-color: #ff51000a;
}

.edit-red-icon-box .edit-red-icon {
  width: 23%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-red-icon img {
  width: 100%;
}

.edit-red-icon-box h4.edit-red-tx {
  color: #FF5100;
  font-size: 18px;
  font-family: 'Lato';
  font-weight: 400;
  margin: auto;
}

.main-header-Dashbord {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: auto;
  padding: 15px 2%;
  box-sizing: border-box;
  align-items: center;
  border-bottom: 1px solid #D9E1E7;
}

.dropdown-basic {
  display: none !important;
}

.bell_dropdown {
  background-color: white !important;
  border: none !important;
  outline: none !important;
}

.bell_dropdown_items {
  width: 200px !important;
  position: absolute !important;
  top: 0;
  height: fit-content;
}

.bell_dropdown_menu {
  width: 200% !important;
}

.bell_dropdown_message {
  width: 100% !important;
  font-size: 13px
}

.bell_dropdown_view_all:hover {
  background-color: transparent !important;
}

.bell_dropdown_badge {
  position: absolute !important;
  top: 0;
  right: 0;
}

.bell_dropdown::after {
  display: none !important;
}


@media (max-width:767px) {
  .main-header-Dashbord {
    padding: 15px 15% 15px 15px;
  }
}

.main-header-Dashbord .back-button {
  width: 16%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.back-button .back-button-icon {
  width: 20%;
}

.back-button-icon img {
  width: 100%;
}

.main-header-Dashbord .header-side-two {
  width: 22%;
  display: flex;
  align-items: center;
}

.dashbord-heading-tag h3.dashbord-heading {
  font-family: 'Lato';
  font-size: 16px;
  font-weight: bold;
  margin: auto;
  color: #1D1D1D;
}

.dashbord-heading-tag p.dashbord-tag-line {
  font-family: 'Lato';
  font-weight: 400;
  font-size: 14px;
  margin: 11px 0 0 0;
  color: #828282;
}

.sidebarContainer {
  position: relative;
  background: #0062F4;
  width: 25%;
}

section.sidebar {
  background: #0062F4;
  width: 20%;
  padding: 20px;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  bottom: 0;
}

.sidebar::-webkit-scrollbar {
  display: none;
}

section.sidebar .logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

section.sidebar .txt {
  padding: 0% 5%;
}

@media (min-width: 750px) and (max-width: 991.98px) {
  section.sidebar .txt {
    text-align: center;
  }

  section.sidebar .sidebar-nav {
    width: 100%;
    padding: 25px 0;
    position: relative;
    margin: 0 !important;
    left: 0% !important;
  }
}

section.sidebar .logo h2 {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  padding-left: 20px;
  text-transform: uppercase;
  margin: 0;
  line-height: 22px;
}

section.sidebar ul {
  margin: 0;
  padding: 0;
}

section.sidebar ul li {
  list-style: none;
  display: flex;
  align-items: center;
  padding: 10px 0;
  position: relative;
}

section.sidebar ul li h3,
section.sidebar ul li h3 a {
  margin: 0;
  padding-left: 10px;
  font-family: Lato;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  z-index: 2;
}

section.sidebar .sidebar-nav {
  width: 100%;
  padding: 25px 0;
  position: relative;
}

section.sidebar .sidebar-nav:after {
  content: '';
  background: #fff;
  width: 100%;
  margin: auto;
  height: 2px;
  position: absolute;
  bottom: 0;
  opacity: 0.5;
  left: 0;
}

section.sidebar .sidebar-nav h2 {
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
}

section.sidebar ul li:before {
  content: '';
  background: #fff;
  width: 0;
  position: absolute;
  height: 100%;
  opacity: 16%;
  border-radius: 0 15px 15px 0;
  left: -40px;
  transition: all 0.3s ease;
}

section.sidebar ul li:hover:before {
  width: 100%;
}

section.sidebar ul li:hover h3 {
  font-weight: bold;
}

.das-main {
  display: flex;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden !important;
}

section.das-cntn {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: #f4f8ff;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

section.Dashbord-page-3-sec-one .dashbord-container {
  width: 1376px;
  margin: auto;
}

section.Welcome-Back {
  width: 100%;
  float: left;
  background-color: #f4f8ff;
  padding: 50px 25px 0 25px;
  box-sizing: border-box;
}

.Welcome-Back .container-Welcome-Back {
  width: 100%;
  margin: auto;
  background: url(./images/dashboardHeading.PNG);
  background-size: 22%;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 23px;
  border: 1px solid #D9E1E7;
  background-position: right;
  background-repeat: no-repeat;
}

.container-Welcome-Back h1.Welcome-Back-heading {
  font-family: 'Lato';
  font-size: 30px;
  font-weight: 600;
  margin: auto;
  color: blue;
}

.container-Welcome-Back p.Welcome-Back-tag-line {
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 400;
  margin: 20px 0 20px 0px;
}

.container-Welcome-Back button.btns7 {
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  background: transparent;
  border: none;
  text-decoration: underline;
  line-height: 23px;
}

section.Assessor-Dashbord-page1-sec-two {
  width: 100%;
  float: left;
  background-color: #f4f8ff;
  padding: 0 25px;
  box-sizing: border-box;
  overflow: hidden;
  margin: 0 0 10px 0;
}

.Assessor-Dashbord-page1-sec-two .assessor-dashbord-container {
  width: 100% !important;
  margin: auto;
  display: table;
}

.Dashbord-3-top-boxes-page .Overall-Progress {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  margin: 2%;
}

@media(max-width:768px) {
  .Welcome-Back .container-Welcome-Back {
    background: none;
  }
}

@media(max-width:700px) {
  .Assessor-Dashbord-page1-sec-two {
    margin: 0px !important;
    padding: 0px 3px !important;
    overflow-x: auto !important;
  }

  .Dashbord-3-top-boxes-page .Overall-Progress {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    overflow: auto;
    margin: 0px !important;
    justify-content: center !important;
  }

  .Dashbord-page-3-sec-one .assessor-dashbord-container .Overall-Progress .Overall-Progress-tx2 {
    font-size: 20px;
    margin: 20px 10px !important;
  }

  .Assignments-that-needs-grading-select-boxes {
    display: flex;
    flex-direction: column;
  }

  .Assignments-that-needs-grading-select-boxes {
    width: 100% !important;
    display: flex;
    flex-direction: row !important;
    justify-content: flex-start !important;
    padding-left: 2%;
    column-gap: 10px;
    padding-right: 0%;
  }

  .Assignments-that-needs-grading-select-boxes input {
    width: 80%;
    height: auto;
    outline: none;
  }

  .Assignments-that-needs-grading-select-boxes select {
    width: 25% !important;
    margin: 1% 0% !important;
    outline: none;
    height: auto;
  }
}

.Assignments-that-needs-grading-select-boxes input {
  outline: none;
}

.Assignments-that-needs-grading-select-boxes select {
  outline: none;
}


@media(min-width:701px) and (max-width:1252px) {
  .Dashbord-page-3-sec-one .assessor-dashbord-container .Overall-Progress .Overall-Progress-tx2 {
    font-size: 20px;
    margin: 20px 10px !important;
  }

  section.Dashbord-page-3-sec-one {
    padding: 0px !important;
  }

  .Assignments-that-needs-grading-select-boxes {
    width: 70% !important;
  }
}

.dashbord-container .Overall-Progress {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 0 25px 0;
}

.Dashbord-page1-sec-two {
  width: 100%;
  float: left;
  background-color: #f4f8ff;
  padding: 0 25px;
  box-sizing: border-box;
  overflow: hidden;
}

.learner-dashbord-container .Overall-Progress {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 50px 25px 0 25px;
}

.learner-dashbord-container .Progress-boxes {
  width: 100%;
  display: flex;
  margin: auto;
  align-items: center;
  /* justify-content: center; */
  box-sizing: border-box;
  padding: 20px 2%;
  flex-wrap: wrap;
}

@media(min-width:1400px) {
  .learner-dashbord-container .Progress-boxes {
    padding: 20px 1% !important;
  }
}


@media (max-width: 991px) {
  .Overall-Progress .Overall-Progress-txs {
    width: 100%;
  }
}

@media (min-width:768px) and (max-width: 991px) {
  .assessor-dashbord-container .Overall-Progress .Overall-Progress-txs {
    width: 60% !important;
  }
}

.Overall-Progress .Overall-Progress-icons {
  width: 7%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 3px;
  border: 1px solid blue;
  border-radius: 4px;
}

.Overall-Progress-icons .Overall-Progress-icon-one {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media(max-width:768px) {
  .Overall-Progress .Overall-Progress-icons {
    width: 25%;
    height: 4vh;
    border: none;
    padding: 0px;
  }

  .Overall-Progress-icons .Overall-Progress-icon-one img {
    width: 100% !important;
    height: 20% !important;
  }
}

.Overall-Progress-txs h2.Overall-Progress-tx1,
.Overall-Progress-tx1 {
  font-family: 'Lato';
  font-size: 30px;
  font-weight: 600;
  margin: auto;
  color: blue;
}

@media(max-width:768px) {

  .Overall-Progress-txs h2.Overall-Progress-tx1,
  .Overall-Progress-tx1 {
    font-size: 20px;
    font-weight: 600;
    margin: 10px 0px;
  }
}

.plannedActions {
  font-family: 'Lato';
  font-size: 20px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  padding: 50px 25px 0px 25px;
  ;
}


.plannedActions-slider-container {
  background-color: white;
  box-shadow: 2px gray;
  padding: 4% 10%;
}

.slick-prev:before,
.slick-next:before {
  font-family: '.slick';
  font-size: 20px !important;
  line-height: 1;
  opacity: .75;
  color: blue !important;
}


@media (max-width:768px) {
  .plannedActions-slider-container {
    padding: 10% 10%;
  }

  .SlideMain {
    background-color: #f5f8fe;
    padding: 2% 4%;
    border-radius: 10px;
    font-size: 10px !important;
  }

  .sliderDates {
    flex-direction: column !important;
  }
}

.SlideMain {
  background-color: #f5f8fe;
  padding: 2% 4%;
  border-radius: 10px;
  height: 400px;
  overflow-y: auto;
}

.SlideMain::-webkit-scrollbar {
  width: 5px;
}
.SlideMain::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

.SlideMain::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.sliderDates {
  display: flex;
  justify-content: space-between;
}

.Overall-Progress-txs p.Overall-Progress-tx2 {
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 400;
  margin: 12px 0 12px 0px;
}

.dashbord-container .Progress-boxes {
  width: 100%;
  display: flex;
  margin: auto;
  align-items: center;
  box-sizing: border-box;
  padding: 20px 2%;
  flex-wrap: wrap;
}

.Progress-boxes .Progress-box-one {
  width: 15%;
  text-align: center;
  background-color: #fff;
  box-sizing: border-box;
  padding: 15px 10px;
  border: 1px solid #20202017;
  border-radius: 6px;
  text-decoration: none;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .dashbord-container .Progress-boxes {
    padding: 2% 10%;
  }

  .learner-dashbord-container .Progress-boxes {
    padding: 2%;
  }

  .Mycourses-dashbord-container .Progress-boxes {
    padding: 0% !important;
  }


}


@media (max-width: 436px) {
  .Progress-boxes .Progress-box-one {
    width: 100%;
    padding: 15px 15px;
  }
}

.Progress-box-one h3.Progress-bar-tx {
  font-family: 'Lato';
  font-size: 20px;
  font-weight: bold;
  color: #1D1D1D;
  margin: 10px 0 0 0;

}

section.footerwrapper {
  width: 100%;
  float: left;
  padding: 0 10 px;
  box-sizing: border-box;
  background-color: #f4f8ff;
}

.dashbord-main-footer {
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;

}

.dasfooter {
  box-sizing: border-box;
  background: #F4F8FF;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 1376px;
  margin: auto;
}

.dashbord-main-footer .dashbord-footer-col-1 {
  width: 29%;

  box-sizing: border-box;
}

.dashbord-footer-col-1 .icon-box {
  width: 100%;
  display: flex;
}

.dashbord-footer-col-1 .icon {
  width: 14%;
}

.dashbord-footer-col-1 .icon-text {
  width: 84%;
}

.dashbord-footer-col-1 p.footer-tx {
  font-size: 14px;
  font-family: 'Lato';
  font-weight: 400;
  line-height: 24px;
  color: #333333;
}

.dashbord-main-footer .dashbord-footer-col-2 {
  width: 13%;
  margin: 0 0 0 80px;
}

.dashbord-footer-col-2 h4.Resources {
  font-family: 'Lato';
  font-size: 17px;
  font-weight: 600;
  margin: auto;
}

.dashbord-footer-col-2 ul.footer-ul {
  list-style: none;
  padding: 7px 0 0px 0;
}



.Resources-li a {
  text-decoration: none;
  color: #333333;
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 400;

  line-height: 34px;
}

.dashbord-footer-col-2 .social-icons {
  display: flex;
  width: 85%;
  justify-content: space-between;
}




section.Dashbord-banner {
  width: 100%;
  float: left;
  background: url(./images/Banner.jpg);
  height: 320px;
  padding: 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-sizing: border-box;
}

.Dashbord-banner .main-banner {
  width: 100%;
  padding: 10% 0 0 0;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}


.main-banner h2.Dashbord-unit-1-heading {
  font-size: 42px;
  color: #ffffff;
  font-family: 'Lato';
  margin: 0 0 0 0;
  padding: 15px 0 0 0;
  line-height: 60px;
  width: 100%;

}


section.sidebar ul li.logout {
  cursor: pointer;
}

section.Dashbord-page2-sec-two {
  width: 100%;
  float: left;
  background-color: #fff;
  padding: 50px 0 60px 0;
}

.Dashbord-page2-sec-two .dashbord-container {
  width: 100%;
  margin: auto;
  display: table;
  padding: 0 25px;
  box-sizing: border-box;
}


.main-banner .dashbord-banner-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
}

.dashbord-banner-buttons button.btns8 {
  font-size: 16px;
  font-family: 'Lato';
  font-weight: 500;
  background-color: transparent;
  color: #FFFFFF;
  border: 1px solid #fff;
  border-radius: 6px;
  padding: 10px 50px;
  white-space: nowrap;

}

.Dashbord-page2-sec-two h2.Dashbord-page-2-sec-two-heading {
  font-family: 'Lato';
  font-weight: 800;
  font-size: 32px;
  color: #000;
}

.Dashbord-page2-sec-two p.Dashbord-page-2-sec-two-tx {
  font-size: 16px;
  font-family: 'Lato';
  font-weight: 400;
  line-height: 26px;
  padding: 0 0 50px 0;
}


@media(max-width:768px) {
  .Dashbord-banner .main-banner {
    flex-direction: column;
    width: 100% !important;
    padding: 0px !important;
  }

  .main-banner h2.Dashbord-unit-1-heading {
    font-size: 38px;
    color: #ffffff;
    font-family: 'Lato';
    margin: 0 0 0 0;
    padding: 15px 0 0 0;
    line-height: 60px;
    width: 100%;
  }

  section.Dashbord-banner {
    padding: 20px !important;

  }

  .dashbord-banner-buttons {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

  }

  .dashbord-banner-buttons button.btns8 {
    width: 100%;
  }
}


h4.Unit-Progress-box-1-heading {
  font-family: 'Lato';
  font-weight: bold;
  font-size: 20px;
  margin: 0 0 0 0;
}

.Dashbord-page2-sec-two .dashbord-container .Progress-boxes .Unit-Progress-box-2 a {
  padding: 20px;
  box-sizing: border-box;
  display: block;
  text-decoration: none;
  color: #000;
  width: 100%;
  height: 200px;
}

.Dashbord-page2-sec-two .dashbord-container .Progress-boxes .Unit-Progress-box-2 {
  width: 23%;
  box-sizing: border-box;
  border: 1px solid #FF5100;
  background-color: #ff510012;
  border-radius: 10px;
  margin: 0.8%;
}

.Progress-boxes .Unit-Progress-box-2 .Progress-bar-image {
  width: 100%;
  height: 10px;
  border-radius: 10px;
  background: #fff;
  margin-top: 10px;
}

@media(max-width:768px) {
  .Dashbord-page2-sec-two .dashbord-container .Progress-boxes .Unit-Progress-box-2 {
    width: 100%;
    border-radius: 10px;
    margin: 2%;
  }
}


.Overall-Progress-txs h2.Overall-Progress-tx2 {
  font-family: 'Lato';
  font-size: 30px;
  font-weight: 600;
  margin: auto;
  color: black;
}

.Progress-boxes .Unit-Progress-box-1 a {
  padding: 32px 15px 32px 15px;
  box-sizing: border-box;
  display: block;
  text-decoration: none;
}

.Progress-boxes .Unit-Progress-box-3 a {
  box-sizing: border-box;
  display: block;
  padding: 20px 15px 13px 15px;
  text-decoration: none;
}

.Progress-boxes .Unit-Progress-box-4 a {
  box-sizing: border-box;
  display: block;
  padding: 20px 15px 13px 15px;
  text-decoration: none;
}

.Progress-boxes .Unit-Progress-box-1 {
  width: 23.5%;
  box-sizing: border-box;
  border: 1px solid #64A338;
  background-color: #64a3381f;
  border-radius: 10px;
}

.Progress-boxes .Unit-Progress-box-3 {
  width: 23.5%;
  box-sizing: border-box;
  border: 1px solid #0062F4;
  background-color: #0062f41c;
  border-radius: 10px;
}

.Progress-boxes .Unit-Progress-box-4 {
  box-sizing: border-box;
  border: 1px solid #FFCC00;
  background-color: #ffcc001c;
  border-radius: 10px;
  width: 23.5%;
}

p.Unit-Progress-box-1-tx {
  font-size: 16px;
  font-family: 'Lato';
  font-weight: 400;
  line-height: 25px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 32%;
}

.Completed-icon-box {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 9px 0 0 0;
}

.Completed-icon-box .Completed-icon {
  width: 11%;
}

.Completed-icon-box h5.Completed-tx {
  font-family: 'Lato';
  font-weight: 500;
  font-size: 18px;
  color: #64A338;
  margin: 0 0 0 0;
}

.Completed-icon img {
  width: 64%;
}

.Unit-Progress-status {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

button.btns9 {
  font-size: 16px;
  font-family: 'Lato';
  font-weight: 500;
  background-color: transparent;
  color: #0062F4;
  border-bottom: 1px solid #0062F4;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  cursor: pointer;
}

.completion-percentage-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 0 0;
}

h5.Completed-tx {
  font-family: 'Lato';
  font-weight: 400;
  font-size: 16px;
  color: #4F4F4F;
  margin: 0 0 0 0;
}

h6.completion-percentage {
  font-size: 16px;
  font-family: 'Lato';
  font-weight: 600;
  margin: 0 0 0 0;
}

.progress-bar-fill {
  width: 100%;
  height: 100%;
  background: #64A338;
  border-radius: 10px;
  transition: all 0.5s ease;
}

section.custabs {
  min-height: 100vh;
  width: 100%;
  float: left;
}

section.custabs .ant-tabs-tab-btn {
  font-family: 'Lato';
  font-size: 20px;
  font-weight: 600;
  color: #909090 !important;
}

section.custabs .ant-tabs-tab-btn span {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

section.custabs .ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: bold;
  color: #0062F4 !important;
}

section.custabs .ant-tabs-tab-active .ant-tabs-tab-btn img {
  filter: invert(35%) sepia(62%) saturate(5916%) hue-rotate(207deg) brightness(92%) contrast(111%);
}

@media(max-width:700px) {
  section.custabs .ant-tabs-nav-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100% !important;
    text-align: center;
    align-items: center;
  }

  .custabs .subcustabs2 .ant-tabs-nav-list {
    flex-direction: row;
    padding: 2%;
    column-gap: 10px;
  }

  .subcustabs2 .ant-tabs-nav-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap !important;
    justify-content: flex-start;
    align-self: flex-start !important;
  }

  .col-tabs .ant-tabs-nav-wrap {
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  .col .ant-tabs-nav {
    width: 100%;
  }

  .ant-tabs-tab {
    margin: 0px !important;
    padding: 0px;
  }

  #progress-percentage {
    padding: 0px 12px !important;
    text-align: center !important;
  }

  .custabs2 {
    flex-direction: column !important;
  }

  .custabs2 .ant-tabs-tab {
    width: 80% !important;
    margin: 2% 20% !important;
  }

  .custabs2 .subcustabs2 .ant-tabs-tab {
    width: 20% !important;
    margin: 0% !important;
  }

  .assignmentType .ant-tabs-nav-list {
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
    padding: 0% 10% !important;
  }

  .custabs2 .subcustabs2 .assignmentType .ant-tabs-tab {
    width: 100% !important;
  }

}

@media(min-width:701px) and (max-width:991px) {
  section.custabs .ant-tabs-nav-list {
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 100% !important;
    text-align: center;
    align-items: center;
  }

  .custabs .subcustabs2 .ant-tabs-nav-list {
    flex-direction: row;
    padding: 2%;
    column-gap: 10px;
  }

  .subcustabs2 .ant-tabs-nav-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap !important;
    justify-content: flex-start;
    align-self: flex-start !important;
  }

  .col-tabs .ant-tabs-nav-wrap {
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  .col .ant-tabs-nav {
    width: 100%;
  }

  .ant-tabs-tab {
    margin: 0px 10px !important;
    padding: 0px;
  }

  #progress-percentage {
    padding: 0px 12px !important;
    text-align: center !important;
  }


  .subcustabs2 .ant-tabs-tab {
    width: 20% !important;
    margin: 0% !important;
  }
}

@media(min-width:992px) and (max-width:1100px) {
  section.custabs .ant-tabs-nav-list {
    display: flex;
    flex-direction: row;
    justify-content: start !important;
  }

  .ant-tabs-tab {
    margin: 10px !important;
    padding: 0px;
  }
}

section.Dashbord-page3-sec-two {
  width: 100%;
  float: left;
  background-color: #f4f8ff;
  padding: 50px 0 60px 0;
}

.Dashbord-page3-sec-two .dashbord-container {
  width: 100%;
  margin: auto;
  display: table;
  padding: 0 25px;
  box-sizing: border-box;
}

.Dashbord-sec-two {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 30px;
}

.Calander-page-sec-one-left {
  width: 65%;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #0000000f;
}

.Calander-page-sec-one-right {
  width: 35%;
  background: #fff;
  box-sizing: border-box;
  padding: 31px 0 100px 1px;
  border-radius: 4px;
  border: 1px solid #0000000f;
}

h2.Calander-page-sec-one-right-heading {
  font-family: 'Lato';
  font-size: 22px;
  font-weight: bold;
  color: #000;
  margin: 0 0 0 20px !important;
}

h3.Tasks-title {
  color: #0062F4;
  font-size: 16px;
  font-family: 'Lato';
  font-weight: 600;
  padding-left: 20px !important;
  border-left: 3px solid blue;
}


p.Tasks-tx {
  font-family: 'Lato';
  font-size: 16px;
  color: #828282;
  font-weight: 400;
  padding: 0 0 0 25px;
  margin: 8px 0 56px 0;
}

.Dashbord-sec-three {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0 0 0;
}

.Upcoming-Deadlines {
  width: 50%;
  box-sizing: border-box;
  background-color: #fff;
  padding: 20px;
  margin: 0 15px 0 0;
}

.Assessors-availability {
  width: 50%;
  box-sizing: border-box;
  background-color: #fff;
  padding: 20px;
  margin: 0 0 0 15px;
}

h3.Upcoming-Deadlines-heading {
  font-size: 20px;
  font-family: 'Lato';
  font-weight: bold;
  margin: 0 0 20px 0 !important;
}

div.Task-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #00000036;
  padding: 2% !important;
}

h4.Task-title-heading {
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  margin: 15px 0 12px 0;
}

h4.\32 4-Dec {
  font-size: 16px;
  font-family: 'Lato';
  font-weight: bold;
  color: #FF5100;
  margin: 0 0 0 0;
}


h4.Time {
  font-size: 16px;
  font-family: 'Lato';
  font-weight: bold;
  color: #1D1D1D;
  margin: 0 0 0 0;
}

.Calander-page-sec-one-left .ant-picker-body {
  padding: 0 !important;
}

.Calander-page-sec-one-left .ant-picker-calendar-header {
  padding-left: 10px;
  padding-right: 10px;
}

.Calander-page-sec-one-left table.ant-picker-content th {
  padding: 10px !important;
}

@media(max-width:768px) {

  .Dashbord-sec-three,
  .Dashbord-sec-two {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    flex: wrap;
    flex-wrap: wrap-reverse;
  }

  .Dashbord-sec-three {
    flex-direction: column;
    justify-content: center !important;
  }

  .Calander-page-sec-one-left,
  .Calander-page-sec-one-right {
    width: 100%;
    margin-bottom: 5%;
  }

  .Upcoming-Deadlines,
  .Assessors-availability {
    width: 100%;
    margin: 0px 0px 4% 0px !important;
  }

  .Task-title-heading,
  .Time {
    font-size: 14px !important;
  }
}

.AssessorCalendarBottomSection {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 30px 0 0 0;
  column-gap: 10px;
}

.AssessorCalendar-Sec1 {
  width: 50%;
}

.AssessorCalendar-Sec2 {
  width: 50%;
}

.YourAvailability,
.Assessor-ZoomCalls,
.Assessor-Meetings {
  padding: 2%;
  margin: 2%;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 2%;
}

@media(max-width:767px) {
  .AssessorCalendarBottomSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px 0 0 0;
    column-gap: 10px;
  }

  .AssessorCalendar-Sec1 {
    width: 100%;
  }

  .AssessorCalendar-Sec2 {
    width: 100%;
  }
}

.empty-calendar {
  margin: 10%;
  column-gap: 20px;
  align-items: center;
}

.emptycalendarimage {
  width: 140px;
}

.emptycalendartext p {
  font-size: 16px !important;
  margin: 0px;
}

.Addnow {
  font-weight: 500;
  text-decoration: underline;
}

@media(max-width:500px) {
  .empty-calendar {
    flex-direction: column;
    row-gap: 40px;
  }
}


section.Dashbord-page-3-sec-one {
  width: 100%;
  float: left;
  padding: 50px 25px 50px 25px;
  background-color: #f4f8ff;
  box-sizing: border-box;
  overflow: hidden;
}

.Dashbord-page-3-top-boxes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  overflow: hidden !important;
}

.Dashbord-page-3-top-box-1 {
  background: url(./images/Rectangle-one.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
}

.Dashbord-page-3-top-box-2 {
  background: url(./images/Rectangle-two.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 49%;
}

.Dashbord-page-3-top-box-1 {
  padding: 17px;
  border-radius: 10px;
  margin: 0 0px 0 0;
}

.Dashbord-page-3-top-box-2 {
  padding: 17px;
  border-radius: 10px;
  margin: 00 0 0 10px;
}

h1.Welcome-Back-heading {
  font-size: 32px;
  font-family: 'Lato';
  font-weight: bolder;
  margin: auto;
  color: #0062F4;
}

p.Welcome-Back-tag-line {
  font-size: 16px;
  font-family: 'Lato';
  font-weight: 400;
}


.dashboard-Comments-Section {
  padding: 50px 25px;
  display: flex;
  column-gap: 10px;
  flex-wrap: wrap;
}

.Programme-Reviews,
.Programme-Assessment {
  width: 49%;

}

.Programme-Reviews-Main,
.Programme-Assessment-Main {
  background-color: white;
  padding: 2%;
  border-radius: 10px;
  box-shadow: gray;
  height: 350px;
}

.Programme-Assessment-Main {

  background-image: url("images/dashboardcomment.PNG");
  background-repeat: no-repeat;
  background-position: bottom right;
}

.Programme-Reviews-Main {
  display: flex;
  justify-content: space-between;
  background-image: url("images/dashboardReviews.PNG");
  background-repeat: no-repeat;
  background-position: bottom right;
}

.Programme-Reviews-Container {
  display: flex;
  flex-direction: column;
}

.Programme-Assessment-Container {
  display: flex;
  justify-content: space-between;
}

.Programme-Reviews-Details {
  padding: 1%;
  width: 200px !important;
  font-size: 12px;
}

.Programme-Assessment-Details {
  padding: 1%;
  width: 140px !important;
  font-size: 12px;
}

.Programme-Assessment-Details-Month {
  padding: 1%;
  width: 180px !important;
  font-size: 12px;
}

.programme-heading {
  height: 60px;
  display: flex;
  align-items: center;
}

.dashboardCommentImage {
  width: 279px;
}

.Programme-Review-Photo {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.left-blue-border {
  border-left: 4px solid blue;
  padding-left: 10px;
}

.left-orange-border {
  border-left: 4px solid #ff702c;
  padding-left: 10px;
}

.next-review-text {
  color: #ff702c;
  font-weight: 500;
}

.overall-programme-text {
  font-weight: 500;
}

@media(max-width:800px) {
  .dashboard-Comments-Section {
    padding: 50px 0px;
    display: flex;
    column-gap: 0px;
    flex-wrap: wrap;
  }

  .plannedActions {
    flex-direction: column;
    font-size: 18px !important;
  }

  .plannedActions h2 {
    font-size: 20px !important;
    margin-bottom: 20px !important;
  }

  .Programme-Reviews,
  .Programme-Assessment {
    width: 100% !important;
  }

  .dashboardCommentImage {
    width: 150px !important;
  }

  .Programme-Reviews-Main,
  .Programme-Assessment-Main {
    background-color: white;
    padding: 4% 2% !important;
    border-radius: 10px;
    box-shadow: gray;
    height: 240px !important;
  }

  .Programme-Assessment-Details {
    padding: 1%;
    width: 100px !important;
    font-size: 10px !important;
    word-wrap: break-word;
  }

  .Programme-Reviews-Details {
    padding: 1%;
    width: 100px !important;
    font-size: 10px !important;
  }

  .Programme-Assessment-Details-Month {
    padding: 1%;
    width: 120px !important;
    font-size: 10px;
  }

  .Programme-Assessment-Main {
    background-size: 50%;
  }

  .Programme-Reviews-Main {
    background-size: 50%;
  }

  .Programme-Assessment-Container {
    display: flex;
    justify-content: flex-start;
  }
}

.Assignments-that-needs-grading-select-boxes {
  width: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Assignments-that-needs-grading-select-boxes input[type="text"] {
  width: 69%;
  box-sizing: border-box;
  padding: 13px;
  border: 1px solid #0000001c;
  border-radius: 5px;
}

.Assignments-that-needs-grading-select-boxes select#rows-per-page {
  width: 29%;
  box-sizing: border-box;
  padding: 13px 8px;
  border: 1px solid #0000001c;
  border-radius: 5px;
}

.Assignments td.grading {
  border-top: 1px solid rgba(0, 0, 0, 0.144);
  border-bottom: 1px solid rgba(0, 0, 0, 0.144);
  text-align: center;
  padding: 12px 0px;
}

.Assignments td.grading a {
  text-decoration: none;
  color: #000;
}

.grading img {
  width: 32%;
}

.dashbord-table tbody {
  background: #fff;
}

.First-line th {
  color: #fff;
  text-align: center;
}

.dashboard-table-actions {
  width: 130px;
  text-align: center !important;
}

.su-sidebar .sidebar-nav:after {
  display: none;
}

tr.First-line {
  background: #0062F4;
}

.dashbord-table tbody {
  border-radius: 10px;
}

table.dashbord-table {
  border-radius: 10px;
}

@media(max-width:768px) {

  .First-line,
  .Assignments td.grading {
    font-size: 14px !important;
  }
}

section.Add-New-Job-Assessment-Record {
  width: 100%;
  background-color: #f4f8ff;
  float: left;
}

.Add-New-Job-Assessment-Record-container {
  width: 100%;
  margin: auto;
  display: table;
}

.Add-New-Job-Assessment-Record-main-one {
  box-sizing: border-box;
  width: 100%;
  background: #fff;
  padding: 40px;
  border-radius: 6px;
  border: 1px solid #00000012;
}

section.Add-New-Job-Assessment-Record {
  box-sizing: border-box;
  padding: 50px 0 50px 0;
}

h1.Add-New-Job-Assessment-Record-heading {
  font-family: 'Lato';
  font-size: 30px;
  font-weight: 600;
  margin: auto;
  color: black;
}

.top-fields {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-col-one {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
}

.form-col-one .field {
  width: 49%;
}

@media(max-width:1080px) {
  .form-col-one .field {
    width: 100%;
  }
}

.top-fields h5 {
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 400;
  margin: auto;
  color: black;
  padding: 0 0 12px 0;
  margin-bottom: 10px !important;
}

h1.Add-New-Job-Assessment-Record-heading {
  padding: 0 0 25px 0;
}

.top-fields input[type="text"],
.top-fields input[type="number"],
.top-fields select,
.top-fields input[type="time"] {
  width: 100%;
  padding: 13px;
  box-sizing: border-box;
  margin: 0 0 23px 0;
  font-family: 'Lato';
  font-size: 16px;
  font-style: italic;
  border-radius: 8px;
  border: 1px solid #00000014;
}

#ReviewTextArea {
  width: 100%;
  min-height: 200px;
  font-family: 'Lato';
  font-size: 16px;
  font-style: italic;
  border-radius: 8px;
  border: 1px solid #00000014;
}

input.Job-Location {
  width: 100%;
  padding: 13px;
  box-sizing: border-box;
  margin: 0 0 23px 0;
  font-family: 'Lato';
  font-size: 16px;
  font-style: italic;
  border-radius: 8px;
  border: 1px solid #00000014;
}

h5.Job-Location {
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 400;
  margin: auto;
  color: black;
  padding: 0 0 12px 0;
}

.Add-New-Job-Assessment-Record-main-one button.btns10 {
  padding: 10px 35px 10px 35px;
  font-family: 'Lato';
  font-size: 18px;
  font-weight: 500;
  background: #1d70ff;
  color: #fff;
  border: none;
  border-radius: 5px;
  margin: 20px 0 0 0;
  cursor: pointer;
}

.Add-New-Job-Assessment-Record-main-two {
  box-sizing: border-box;
  width: 100%;
  background: #fff;
  padding: 40px;
  border-radius: 6px;
  border: 1px solid #00000012;
  margin: 35px 0 0 0;
}

h1.Add-New-Job-Assessment-Record-heading-one {
  font-family: 'Lato';
  font-size: 30px;
  font-weight: 600;
  margin: auto;
  color: black;
}

.Add-New-Job-Assessment-Record-main-two-top-bar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

@media(max-width:768px) {
  .Add-New-Job-Assessment-Record-main-two-top-bar {
    flex-direction: column;
    justify-content: center;
  }
}

.Add-New-Job-Assessment-Record-main-two-top-bar button.btns10 {
  padding: 10px 35px 10px 35px;
  font-family: 'Lato';
  font-size: 18px;
  font-weight: 500;
  background: #1d70ff;
  color: #fff;
  border: none;
  border-radius: 5px;
  margin: 0 0 0 0;
  cursor: pointer;
}

p.Add-New-Job-Assessment-Record-heading-text {
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 400;
  margin: auto;
  color: black;
  padding: 10px 0 0px 0;
}

.sec-two-Underpinning-Questions button.btns2 a {
  color: #fff;
  text-decoration: none;
}

.top-fields input[type="date"] {
  width: 100%;
  padding: 13px;
  box-sizing: border-box;
  margin: 0 0 23px 0;
  font-family: 'Lato';
  font-size: 16px;
  font-style: italic;
  border-radius: 8px;
  border: 1px solid #00000014;
}

.job-editor h5 {
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 400;
  margin: auto;
  color: black;
  padding: 0 0 12px 0;
}

.Learning-Materials-img-box a {
  color: #000;
  text-decoration: none;
}

.custabs2 .ant-tabs-nav:before {
  display: none;
}

.custabs2 .ant-tabs-nav .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  display: none;
}

.custabs2 .ant-tabs-nav .ant-tabs-tab {
  border: 1px solid #05050521;
  padding: 12px;
  border-radius: 5px;
  margin: 0 0 0 20px;
}

section.custabs .custabs2 .ant-tabs-nav .ant-tabs-tab-btn {
  font-size: 14px;
}

.custabs2 .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
  background: #F4F8FF;
}

.custabs2 .ant-tabs-nav .ant-tabs-tab:hover .ant-tabs-tab-btn {
  color: #0062F4 !important;
  font-weight: bold !important;
}

.custabs2 .ant-tabs-nav .ant-tabs-tab:first-child {
  margin: 0;
}

a.tounit {
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  background: transparent;
  border: none;
  text-decoration: underline;
  line-height: 23px;
}

.das-main section.sec-5-unit-Overview {
  padding-left: 25px;
  padding-right: 25px;
  background-color: #f4f8ff;
}

@media (max-width:768px) {
  .Dashbord-page-3-sec-one {
    overflow-x: hidden;
    padding: 10px 0px !important;
  }

  .Dashbord-page-3-sec-two {
    padding: 0px !important;
  }
}

.job-editor p {
  word-break: break-all !important;
}

.Assignments td.grading.action {
  position: relative;
  text-align: center !important;
  padding: 2%;
}

.Students td.grading.action {
  position: relative;
  text-align: center !important;
}

@media(max-width:1800px) {
  .Assignments td.grading.action {
    padding: 3%;
  }
}

@media(max-width:1500px) {
  .Assignments td.grading.action {
    padding: 4%;
  }
}


.view-submitted-icon {
  width: 100% !important;
  height: auto;
}

.Assignments td.grading.action .afaction a {
  padding: 8px 14px;
  box-sizing: border-box;
  display: block;
}

.Assignments td.grading.action .afaction {
  position: absolute;
  right: 50px;
  background: #fff;
  border-radius: 5px;
  top: 34px;
  display: none;
  transition: all 0.3s ease;
}

.hovertoggle {
  display: table;
}

@media(max-width:1000px) {
  .Assignments td.grading.action .afaction {
    right: 58px;
  }
}

.hovertoggle:hover .afaction {
  display: block !important;
}

.Assignments td.grading.action img {
  cursor: pointer;
  width: 50% !important;
}

.job-editor .jodit-workplace {
  min-height: 400px !important;
}

.container-Welcome-Back p.Welcome-Back-tag-line span {
  color: blue;
  font-weight: bold;
}

ul.pagination {
  padding: 2% 0%;
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
}

ul.pagination li.page-item {
  list-style: none;
}

ul.pagination li.page-item a.page-link {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  border: 1px solid #D4D4D4;
  border-radius: 3px;
  cursor: pointer;
}

ul.pagination li.page-item a.page-link:hover {
  background: #005be3;
  color: #fff;
}

ul.pagination li.page-item a.page-link[tabindex]:focus {
  background: #005be3;
  color: #fff;
}

ul.pagination li.active.page-item a.page-link {
  background: #005be3;
  color: #fff;
}

@media (max-width: 1366px) {
  .main-header {
    width: 100%;
    box-sizing: border-box;
    padding: 15px 35px 15px 35px;
  }

  .main-header .header-side-two {
    width: 18%;
  }

  .banner .main-banner {
    width: 100%;
  }

  .main-banner h2.unit-1-heading {
    font-size: 42px;
    width: 100% !important;
  }

  .container {
    width: 100%;
    padding: 0 35px 0 35px;
    box-sizing: border-box;
  }

  .Learning-Materials-img-box p.Learning-Materials-tx {
    font-size: 20px;
  }

  .sec-5-unit-Overview-container {
    width: 100%;
    margin: 0 35px 0 35px;
    box-sizing: border-box;

  }
}

@media (max-width: 600px) {
  .main-banner h2.unit-1-heading {
    font-size: 28px;
    width: 100% !important;
  }

  .unit-1 .unit-icon {
    width: 20%;
  }

  .banner .main-banner {
    padding: 40% 0 0 0;
    margin: auto;
  }
}

@media (max-width: 741px) {
  .sidebar-nav>ul>a>li>h3 {
    display: none;
  }

  .sidebar-nav>ul>li>h3 {
    display: none;
  }

  .logo .txt {
    display: none;
  }

  .sidebar-nav h2 {
    text-align: center;
  }

  section.sidebar .sidebar-nav {
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 728px) {
  section.sidebar .logo img {
    width: 1.5rem;
  }
}

@media (max-width: 625px) {
  section.sidebar .logo h2 {
    display: none;
  }
}

@media(min-width:2200px) {
  section.sidebar .sidebar-nav {
    width: 100%;
    padding: 40px 10% !important;
    position: relative;
    left: 0% !important;
  }
}

@media(min-width:2100px) and (max-width:2200px) {
  section.sidebar .sidebar-nav {
    padding: 40px 6% !important;
    left: 0% !important;
  }
}

@media (max-width: 360px) {
  section.sidebar .sidebar-nav {
    width: 100%;
    padding: 40px 0;
    position: relative;
    margin: 0 20px;
    left: -93%;
  }

  .logo .img {
    margin-right: 20% !important;
  }
}

@media (max-width: 864px) {
  section.sidebar .logo h2 {
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    padding-left: 20px;
    text-transform: uppercase;
    margin: 0;
    line-height: 22px;
  }

  section.sidebar .logo h2 {
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    padding-left: 20px;
    text-transform: uppercase;
    margin: 0;
    line-height: 22px;
  }
}

.CalendarModal {
  padding: 4% 8%;
  text-align: left !important;
}

.CalendarModal p {
  margin: 10px 0 0 0 !important;
}

.CalendarInputBox {
  width: 100% !important;
  box-sizing: border-box;
  height: 40px;
  padding: 1%
}

.DateInput {
  width: 100% !important;
}

.dropdownCalendar {
  position: absolute;
  width: 80px !important;
  padding: 1%;
  float: left !important;
  border-radius: 10%;
  border: 1px solid grey;
  background-color: white;
}

@media (max-width:768px) {
  .dropdownCalendar {
    right: 2%;
  }
}

.accordion-button {
  padding: 0% !important;
}

.accordion-button::after {
  flex-shrink: 0;
  width: 0px !important;
}

.SetAvailabilityModal input {
  border: none;
  outline: none;
}

.SetAvailabilityModal select {
  border: none;
  outline: none;
}

.SetAvailabilityModal tr td {
  column-gap: 0px !important;
}

.CommentsDashboard {
  padding: 2% 0%;
}

.ReviewsDashboard {
  padding: 10% 4%;
}

.ReviewsContainer {
  column-gap: 40px;
  display: flex;
  align-items: center !important;
}

.ReviewDetails {
  padding-top: 1%;
}

.ReviewsButtons {
  display: flex;
  justify-content: flex-end;
}

.comments-table {
  overflow-x: auto !important;
  min-height: 50vh;
}

.wwwe {
  width: 100% !important;
}

@media(min-width:401px) and (max-width:768px) {
  .AssessorHeaderHeading {
    font-size: 18px !important;
    display: flex;
    margin-top: 4% !important;
  }
}

.save_cancel {
  display: flex;
  column-gap: 30px;
}

.answer-subheading {
  color: blue;
  text-transform: capitalize;
}

.save_cancel .save-icon-box .save-tx {
  font-size: 14px !important;
  font-weight: bold !important;
  color: blue !important;
}

.CopyLinkBtn {
  border: none;
  outline: none;
  background-color: rgba(117, 152, 250, 0.623);
  width: fit-content !important;
  padding: 2px 10px !important;
  border-radius: 1px;
}

.termsandservices {
  padding: 8%;
}

.termsandservices p {
  margin: 1%;
}

.splash-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #3f51b5;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
}

.loading-spinner {
  border: 4px solid rgba(255, 255, 255, 0.2);
  border-top-color: white;
  animation: spin 1s linear infinite;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.iframe {
  width: 100%;
  height: 400vh !important;
  min-height: auto;
  resize: both;
  overflow: hidden;
  position: absolute;
}

.custom-modal .modal-dialog {
  max-width: 1000px;
}

.cursor-pointer {
  cursor: pointer;
}

.iframe-container {
  height: 100vh;
  overflow: hidden !important;
}

.notification-container {
  background-color: lightblue;
  border-radius: 0px 10px 0 10px;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 20px 15px;
  margin-bottom: 20px;
  animation: grow 0.5s ease-in forwards;
  display: flex;

}

@keyframes grow {
  from {
    opacity: 0;
    transform: scale(0.8);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.notifications {
  padding: 2%;
}

.notification-container-image {
  width: 10%;
  display: flex;
  align-items: start;
  justify-content: center;
}

.notification-container-text {
  width: 90%;
  font-size: 14px;
  margin-bottom: 2px;
}

.notification-date {
  color: rgb(59, 59, 59);
  text-align: right !important;
  padding: 0%;
  margin: 0%;
  font-weight: 600;
  padding-bottom: 0%;
}

.ant-badge-status-success {
  background-color: green;
}

.qaSelectBox {
  display: flex;
  flex-direction: row-reverse;
} 