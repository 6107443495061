.specific-chat,
.chat-app {
    display: flex;
    height: 100vh;
    font-family: Arial, sans-serif;
}

.specific-chat {
    width: 80%;
}

.user-list {
    width: 20%;
    background: #0062F4;
    padding: 20px;
    border-right: 1px solid #ccc;
    overflow-y: scroll;
    overflow-x: hidden;
}

.user-list::-webkit-scrollbar {
    width: 3px;
}

.user-list::-webkit-scrollbar-thumb {
    background-color: lightblue;
    border-radius: 5px;
}

.user-list::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.user-list p {
    color: white;
    display: flex;
    align-items: center;
    margin: 0 !important;
}

.user-list h2 {
    margin-bottom: 10px;
    font-size: 32px;
    color: white;
}

.user-list ul {
    list-style: none;
    padding: 0;
}

.user-list li {
    padding: 5px;
    cursor: pointer;
    margin: 20px 0px;
}

.user-list li.active {
    background-color: white;
    color: black !important;
    border: 1px solid #000;
    border-radius: 10px;
    font-weight: bold;
}

.user-list li.active p {
    color: black !important;
}

.message-container {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.message-list {
    flex: 1;
    padding: 20px;
    overflow-y: scroll;
}

.message {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
}

.message.user {
    background-color: #d1e7f9;
    align-self: flex-start;
}

.message.other {
    background-color: #e2e2e2;
    align-self: flex-end;
}

.message-sender {
    font-weight: bold;
}

.message-input {
    display: flex;
    padding: 20px;
}

.message-input input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.message-input button {
    margin-left: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.message-input button:hover {
    background-color: #0056b3;
}

.message-box {
    padding: 10px 20px;
}

.sender {
    word-wrap: break-word;
    text-align: right !important;
    padding-right: 3%;
    padding-left: 3%;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 10px 0 10px 0px;
    background-color: lightgreen;
    color: black;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

.sender div {
    display: flex;
    flex-direction: row-reverse;
}

.recipient {
    word-wrap: break-word;
    margin: 10px 0 10px 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 3%;
    padding-right: 3%;
    background-color: rgb(228, 228, 228);
    color: black;
    border-radius: 10px;
}

.chat-sender-image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
}